import React, { Component } from 'react';

class Introduction extends Component {
    render () {
        let file_path = "/images/";
        let workshop_file_image = "2f80a8cb-5acb-46c6-b34d-46eba2488b8c.JPG";
        let workshop_download_image = file_path + workshop_file_image;
        return (
            <div className="text-section workshop-text-section">
                <h5 className="text-section-title">My Portfolio</h5>
                <img src={workshop_download_image} alt="" id="workshop_image" className="workshops-intro-image"></img>
                <small className="picture-description">Community art on skid row</small> 
                <div style={{clear: "both"}}></div>
                <p> Hi, welcome to my portfolio! I've designed this portfolio to
                share my background, experience, and what I'm passionate about.
                I have a masters degree in Transpersonal Psychology with a focus
                on creative holistic arts practicies and currently am working at
                a community mental health center as a paraprofessional
                counselor. </p> <p> As part of my work and studies, I organize
                holistic arts groups, activities, and workshops geared towards
                exploring creativity, play, and building connection. I believe
                that when we come together to create something meaningful, we
                cultivate a sense of purpose within ourselves and our community.
                </p> <p> In my portfolio below, you will find more information
                about my groups, workshops, an art gallery, essays I've written,
                my background and education. </p>
                <div style={{clear: "both"}}></div>
            </div>
        );
    }
}

export default Introduction 