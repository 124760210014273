import React, { Component, useState } from 'react';
import LazyLoadComponent from '../../Apps/LazyComponent/LazyLoadComponent.js';
import AboutHeader from './AboutHeader.js';
import AboutContent from './AboutContent.js';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeButton: 'about2',
        };
      }
      handleClick = (buttonId) => {
        this.setState({ activeButton: buttonId });
      };
  
  render() {
    const { activeButton } = this.state;
    return (
      <div id={this.props.id} className={this.props.className}>
        <div className="unset-all about-page">
          <AboutContent/>
        </div>
      </div>
    );
  }
}

export default About;