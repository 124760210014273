import React, { Component } from 'react';

class SiteTitleSection extends Component {
  render() {
    return (
        <div className="site-title-section">
          <h1 className='site-title-name'>Ryan Henao</h1>
          {/*
          <div className="tagline site-title-description">
            <p>creative psychology</p> 
            <p>expressive arts</p>
            <p>enviornmental consciousness</p>
          </div>*/}
        </div>
    );
  }
}

export default SiteTitleSection;