import React, { Component } from 'react';
import { ScrollToHashElementLink } from '../../Apps/ScrollToHashElement/ScrollToHashElement.js';

class Header extends Component {
  render() {
    return (
        <div className="header">
          <div className="navigation-links">
              <ScrollToHashElementLink className="default-link navigation-link workshops-link" href="#workshops">Introduction</ScrollToHashElementLink>
              <ScrollToHashElementLink className="default-link navigation-link gallery-link" href="#gallery">Art Gallery</ScrollToHashElementLink>
              <ScrollToHashElementLink className="default-link navigation-link essays-link" href="#essays">Essays</ScrollToHashElementLink>
              <ScrollToHashElementLink className="default-link navigation-link about-link" href="#about">About</ScrollToHashElementLink>
          </div>
        </div>
    );
  }
}

export default Header;